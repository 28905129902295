import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { createEmailUser } from '../../utils/apis/userApi';
import ErrorMessage from '../../utils/ErrorMessage';
import GoogleButton from './GoogleButton';

function SignUp() {
  const [processError, setProccessError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    createEmailUser(data.get('email'), data.get('password'), data.get('confirmPassword'))
      .then(() => window.location.reload())
      .catch((error) => setProccessError(error));
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <ErrorMessage error={processError} />
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign Up
      </Button>
      <GoogleButton outputError={(error) => setProccessError(error)} />
    </Box>
  );
}

export default SignUp;