import React from 'react';
import { Box, CircularProgress, } from '@mui/material';

function Spinner() {
  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <CircularProgress size={100} />
    </Box>
  );
}
export default Spinner;