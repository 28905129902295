import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField
} from '@mui/material';
import { signInEmailUser } from '../../utils/apis/userApi';
import ErrorMessage from '../../utils/ErrorMessage';
import GoogleButton from './GoogleButton';

function SignIn() {
  const [processError, setProccessError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signInEmailUser(data.get('email'), data.get('password'))
      .then(() => window.location.reload())
      .catch((error) => setProccessError(error));
  };

  return (
    <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <ErrorMessage error={processError} />
      <TextField
        margin='normal'
        fullWidth
        id='email'
        label='Email'
        name='email'
        autoComplete='email'
        autoFocus
      />
      <TextField
        margin='normal'
        fullWidth
        name='password'
        label='Password'
        type='password'
        id='password'
        autoComplete='current-password'
      />
      <Button
        type='submit'
        fullWidth
        variant='contained'
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </Button>
      <GoogleButton outputError={(error) => setProccessError(error)} />
    </Box>
  );
}

export default SignIn;