import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Paper, Typography } from '@mui/material';
import { getCurrentUser } from '../../utils/apis/userApi';
import ErrorMessage from '../../utils/ErrorMessage';
import Spinner from '../../utils/Spinner';
import { checkSessionValid, getLiveValues, readyClient } from '../../utils/apis/sessionApi';
import UploadControls from './UploadControls';

function Client() {
  const urlParams = useParams();
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useState(null);
  const [currentRound, setCurrentRound] = useState(null);
  const [sessionState, setSessionState] = useState(null);
  const [processError, setProcessError] = useState(null);

  useEffect(() => {
    getCurrentUser().then((resp) => {
      if (resp) {
        setCurrentUser(resp);
        return checkSessionValid(urlParams.code, false)
          .then(() => readyClient(urlParams.code, resp.uid))
          .then(() => getLiveValues({
            uniqueCode: urlParams.code,
            updateCurrentRoundFunc: setCurrentRound,
            updateSessionFunc: setSessionState
          }));
      } else {
        throw new Error('No user found!');
      }
    }).catch((error) => setProcessError(error));
  }, []);

  useEffect(() => {
    if (!sessionState) { return; }
    console.log(`Session state is now ${sessionState}`);
    switch (sessionState) {
      case 1:
        console.log('Client must select images now');
        break;
      case 2:
        console.log('Client must wait unless they own the current image');
        break;
      case 3:
        console.log('Client shall wait for round change');
        break;
      default:
        break;
    }
  }, [sessionState]);

  function getControls() {
    switch (sessionState) {
      case 1:
        return <UploadControls
          sessionCode={urlParams.code}
          currentUser={currentUser}
          getError={(error) => setProcessError(error)}
        />;

      default:
        return <Spinner />;
    }
  }

  return (
    <Container maxWidth='sm'>
      <Box justifyContent='center' alignItems='center' height='70vh' display='flex'>
        <Paper elevation={2} sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='h4' fontWeight={500}>
            {sessionState ?
              `Current Round: ${currentRound}` :
              'Awaiting Round to Start'
            }
          </Typography>
          <ErrorMessage error={processError} />
          {getControls()}
        </Paper>
      </Box>
    </Container>
  );
}

export default Client;