import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Container,
  Divider,
  Typography
} from '@mui/material';
import CameraIcon from '@mui/icons-material/Camera';
import ActiveUser from './ActiveUser';
import { getCurrentUser } from '../../utils/apis/userApi';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Spinner from '../../utils/Spinner';
import HostButtons from './HostButtons';
import ClientButtons from './ClientButtons';
import SessionModal from './SessionModal';

function Home() {
  const [accountState, setAccountState] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [sessionModalOpen, setSessionModalOpen] = useState(false);

  useEffect(() => {
    getCurrentUser().then((resp) => {
      if (resp) {
        setCurrentUser(resp);
        setAccountState(3);
      } else {
        setAccountState(1);
      }
    });
  }, []);

  const getStateElement = () => {
    switch (accountState) {
      case 1:
        return <SignIn />;
      case 2:
        return <SignUp />;
      case 3:
        return <ActiveUser user={currentUser} />;
      default:
        return <Spinner />;
    }
  };

  return (
    <Container maxWidth='xs'>
      <Box
        sx={{
          marginTop: 4,
          paddingBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }}>
          <CameraIcon sx={{ fontSize: 100 }} />
        </Avatar>
        <Typography component='h1' variant='h2'>
          GroupPix
        </Typography>
        {getStateElement()}
        <HostButtons
          accountState={accountState}
          openSessionModal={() => setSessionModalOpen(true)}
          accountChange={(i) => setAccountState(i)}
        />
        <Divider sx={{ mt: 2, color: 'gray' }} flexItem>OR</Divider>
        <ClientButtons disabled={sessionModalOpen} />
      </Box>
      <SessionModal
        open={sessionModalOpen}
        hostUser={currentUser}
        closeModal={() => setSessionModalOpen(false)}
      />
    </Container>
  );
}

export default Home;