import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Fade, Paper, Typography } from '@mui/material';
import { checkSessionValid, getLiveValues, handleSessionStateChange, startNextRound } from '../../utils/apis/sessionApi';
import { getCurrentUser } from '../../utils/apis/userApi';
import ErrorMessage from '../../utils/ErrorMessage';
import Awaiting from './Awaiting';

function Host() {
  const urlParams = useParams();
  const [sessionState, setSessionState] = useState(null);
  const [currentRound, setCurrentRound] = useState(null);
  const [userCount, setUserCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useState(null);
  const [processError, setProcessError] = useState(null);

  useEffect(() => {
    getCurrentUser().then((resp) => {
      if (resp) { setCurrentUser(resp); }
      return checkSessionValid(urlParams.code, true, resp);
    }).catch((error) => setProcessError(error));

    getLiveValues({
      uniqueCode: urlParams.code,
      updateCountFunc: setUserCount,
      updateSessionFunc: setSessionState,
      updateCurrentRoundFunc: setCurrentRound,
      startSessionFunc: startNextRound
    });
  }, []);

  useEffect(() => {
    if (!sessionState) { return; }
    console.log(`Session state is now ${sessionState}`);
    switch (sessionState) {
      case 1:
        console.log('Awaiting clients to submit images');
        handleSessionStateChange(urlParams.code);
        break;
      case 2:
        console.log('Running slideshow');
        break;
      case 3:
        console.log('Changing round');
        break;
      default:
        break;
    }
  }, [sessionState]);

  return (
    <Box>
      <Box position='absolute' sx={{ p: 2 }}>
        <Fade in={true}>
          <Paper elevation={5} sx={{ p: 2, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
            <Typography variant='h5'>Session Code:</Typography>
            <Typography variant='h3' sx={{ textTransform: 'uppercase' }}>{urlParams.code}</Typography>
            <Typography>{userCount} User(s) Joined</Typography>
          </Paper>
        </Fade>
        <ErrorMessage error={processError} />
      </Box>
      <Box textAlign='center' padding={2}>
        <Typography variant='h2' fontWeight={700}>
          { sessionState ?
            `Current Round: ${currentRound}`:
            'Awaiting Users to Join'
          }
        </Typography>
      </Box>
      {sessionState == 1 && <Awaiting />}
    </Box>
  );
}

export default Host;