import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Rating,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ErrorMessage from '../../utils/ErrorMessage';
import { initialiseSession } from '../../utils/apis/sessionApi';

function SessionModal(props) {
  const [processError, setProccessError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    initialiseSession(
      data.get('maxUserCount'),
      data.get('roundCount'),
      data.get('imageCount'),
      props.hostUser
    )
      .then((uniqueCode) => window.location.assign(`/host/${uniqueCode}`))
      .catch((error) => setProccessError(error));
  };

  return (
    <Dialog
      open={props.open}
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle>Configure New Session</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => props.closeModal()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <ErrorMessage error={processError} />
          <Grid container spacing={2} textAlign='center'>
            <Grid item xs={12}>
              <Typography component='legend' >Number of Users</Typography>
              <Rating
                name='maxUserCount'
                defaultValue={2}
                icon={<PersonIcon color='primary' fontSize='inherit' />}
                emptyIcon={<PersonOutlineIcon fontSize='inherit' />}
                size='large'
                max={8}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component='legend'>Number of Rounds</Typography>
              <TextField
                name='roundCount'
                type="number"
                defaultValue={5}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component='legend'>No. Images per User</Typography>
              <TextField
                name='imageCount'
                type="number"
                defaultValue={10}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size='large'
            sx={{ mt: 3, mb: 2 }}
          >
            Start Session
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

SessionModal.propTypes = {
  open: PropTypes.bool,
  hostUser: PropTypes.object,
  closeModal: PropTypes.func
};

export default SessionModal;