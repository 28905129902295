import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { signInGoogleUser } from '../../utils/apis/userApi';

function GoogleButton(props) {
  const handleSubmit = (event) => {
    event.preventDefault();
    signInGoogleUser()
      .then(() => window.location.reload())
      .catch((error) => props.outputError(error));
  };

  return (
    <Button
      onClick={handleSubmit}
      sx={{ mb: 2 }}
      variant='contained'
      color='secondary'
      fullWidth
      startIcon={<GoogleIcon />}
    >
      Sign In with Google
    </Button>
  );
}

GoogleButton.propTypes = {
  outputError: PropTypes.func
};

export default GoogleButton;