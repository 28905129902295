import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  TextField
} from '@mui/material';
import { initAnonUser } from '../../utils/apis/userApi';
import ErrorMessage from '../../utils/ErrorMessage';
import { joinSession } from '../../utils/apis/sessionApi';

function ClientButtons(props) {
  const [processError, setProccessError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const sessionCode = data.get('code').toUpperCase();
    initAnonUser()
      .then((uid) => joinSession(sessionCode, uid))
      .then(() => window.location.assign(`/client/${sessionCode}`))
      .catch((error) => setProccessError(error));
  };

  return (
    <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <ErrorMessage error={processError} />
      <TextField
        margin='normal'
        variant='filled'
        inputProps={{
          style: {
            textAlign: 'center',
            fontSize: 30,
            textTransform: 'uppercase'
          }
        }}
        disabled={props.disabled}
        fullWidth
        id='code'
        label='Session Code'
        placeholder='XXXXX'
        name='code'
      />
      <Button
        type='submit'
        disabled={props.disabled}
        fullWidth
        variant='contained'
        sx={{ mt: 2, mb: 2 }}
      >
        Join Session
      </Button>
    </Box>
  );
}

ClientButtons.propTypes = {
  disabled: PropTypes.bool
};

export default ClientButtons;