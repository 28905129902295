import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Button, ImageList, ImageListItem, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { checkImagesExist, getSessionData, uploadImages } from '../../utils/apis/sessionApi';
import imageCompression from 'browser-image-compression';
import Spinner from '../../utils/Spinner';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function UploadControls(props) {
  const [imagesSubmitted, setImagesSubmitted] = useState(false);
  const [maxImageCount, setMaxImageCount] = useState(1);
  const [imagesValid, setImagesValid] = useState(false);
  const [base64Images, setBase64Images] = useState([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    getSessionData(props.sessionCode, 'imageCount')
      .then((value) => setMaxImageCount(value))
      .catch((error) => props.getError(error));

    checkImagesExist(props.sessionCode, props.currentUser.uid)
      .then((value) => {
        if (value) { setImagesSubmitted(true); }
      })
      .catch((error) => props.getError(error));
  }, []);

  const handleImageSubmit = () => {
    setProcessing(true);
    setImagesValid(false);
    uploadImages(
      props.sessionCode,
      props.currentUser.uid,
      base64Images
    )
      .then(() => {
        setImagesSubmitted(true);
        setProcessing(false);
      })
      .catch((error) => props.getError(error));
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const base64ImagesArray = [];
    props.getError(null);

    // Check if the number of selected files exceeds the limit
    if (files.length > maxImageCount) {
      props.getError(Error(`You can only select up to ${maxImageCount} images.`));
      event.preventDefault(); // Prevent exceeding the limit
    } else {
      setImagesValid(false);
      setProcessing(true);

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        try {
          // Create an image element and load the image file
          const img = new Image();
          img.src = URL.createObjectURL(file);

          // Wait for the image to load
          img.onload = async () => {
            // Create a canvas to draw the image in PNG format
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            // Convert the canvas content to a PNG base64 data URI
            const base64Data = canvas.toDataURL('image/png');

            // Convert the base64 data URI to a Blob
            const blob = await fetch(base64Data).then((res) => res.blob());

            // Compress the PNG image using browser-image-compression
            const compressedFile = await imageCompression(blob, {
              maxSizeMB: 5,
              maxWidthOrHeight: 1200,
              useWebWorker: true,
            });

            // Convert the compressed image to base64
            const reader = new FileReader();
            reader.onload = (e) => {
              const base64CompressedData = e.target.result; // Use the full data URI
              base64ImagesArray.push(base64CompressedData);
              console.log(base64CompressedData);

              if (base64ImagesArray.length === files.length) {
                // When all files are processed, set the state
                setBase64Images(base64ImagesArray);
                setProcessing(false);
                setImagesValid(true);
              }
            };
            reader.readAsDataURL(compressedFile);
          };
        } catch (error) {
          props.getError(Error('Error compressing image'));
        }
      }

      // Log the number of selected files
      console.log('Number of selected files:', files.length);
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mt: 2,
      mb: 2,
      width: '100%',
    }}>
      <Button
        component="label"
        startIcon={<UploadIcon />}
        variant='contained'
        size='large'
        disabled={processing || imagesSubmitted}
        sx={{ marginBottom: 2 }}
        fullWidth
      >
        Upload (Max {maxImageCount})
        <VisuallyHiddenInput
          type='file'
          accept='image/*'
          multiple
          onChange={handleFileChange}
        />
      </Button>
      {processing && <Spinner />}
      {!processing && imagesValid &&
        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
          {base64Images.map((img) => (
            <ImageListItem key={img}>
              <img
                src={img}
              />
            </ImageListItem>
          ))}
        </ImageList>
      }
      {imagesSubmitted &&
        <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='h5'>Images Successfully Submitted!</Typography>
          <Typography variant='h6'>Waiting for other users...</Typography>
        </Box>
      }
      <Button
        variant='contained'
        size='large'
        disabled={!imagesValid}
        onClick={() => handleImageSubmit()}
        sx={{ width: imagesValid ? '50%' : '100%' }}
      >
        Submit Images
      </Button>
    </Box>
  );
}

UploadControls.propTypes = {
  sessionCode: PropTypes.string,
  currentUser: PropTypes.object,
  getError: PropTypes.func,
};

export default UploadControls;