import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  signInAnonymously
} from 'firebase/auth';

export function createEmailUser(email, password, confirmPassword) {
  if (password !== confirmPassword) {
    return new Promise(() => {
      throw new Error('The entered passwords do not match');
    });
  }

  const auth = getAuth();
  return createUserWithEmailAndPassword(auth, email, password);
}

export function initAnonUser() {
  const auth = getAuth();
  return signInAnonymously(auth)
    .then((resp) => resp.user.uid);
}

export function signInEmailUser(email, password) {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
}

export function signInGoogleUser() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
}

export function getCurrentUser() {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      if (user) {
        resolve(user);
      } else {
        resolve(null);
      }
    }, reject);
  });
}

export function signOutUser() {
  const auth = getAuth();
  return signOut(auth).then(() => {
    return true;
  });
}