import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import './index.css';
import ErrorPage from './components/ErrorPage';
import Home from './components/home/Home';
import Client from './components/client/Client';
import Host from './components/host/Host';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';

const firebaseConfig = {
  apiKey: 'AIzaSyC2fTE6tg9N-oSw1zronaGz9UZifANS-BQ',
  authDomain: 'group-pix.firebaseapp.com',
  projectId: 'group-pix',
  storageBucket: 'group-pix.appspot.com',
  messagingSenderId: '737007914767',
  appId: '1:737007914767:web:e949182858ccd5752a787a',
  measurementId: 'G-VJ1PVZ6Y3J',
  databaseURL: 'https://group-pix-default-rtdb.europe-west1.firebasedatabase.app'
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: 'host/:code',
    element: <Host />,
    errorElement: <ErrorPage />
  },
  {
    path: 'client/:code',
    element: <Client />,
    errorElement: <ErrorPage />
  }
]);

const mainTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#98FB98', // Pastel Mint
    },
    secondary: {
      main: '#84FFFF', // Pastel Blue
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={mainTheme}>
    <CssBaseline />
    <RouterProvider router={router} />
  </ThemeProvider>
);
