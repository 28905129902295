import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

function Awaiting() {
  return (
    <Box justifyContent='center' alignItems='center' height='70vh' display='flex'>
      <Paper elevation={2} sx={{ padding: 2 }}>
        <Typography variant='h5'>Please select your images from your device for the current round.</Typography>
      </Paper>
    </Box>
  );
}
export default Awaiting;