import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Alert, AlertTitle } from '@mui/material';

function ErrorMessage(props) {
  return (
    <Collapse in={props.error != null}>
      <Alert severity='error' sx={{ mt: 1, mb: 1 }}>
        <AlertTitle>Error</AlertTitle>
        {props.error && (props.error.code || props.error.message)}
      </Alert>
    </Collapse>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.object
};

export default ErrorMessage;