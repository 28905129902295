import { getDatabase, ref, set, child, get, onValue, runTransaction, off } from 'firebase/database';

function generateCode(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

async function getUniqueCode(db) {
  try {
    const dbRef = ref(db);
    let uniqueCode = null;
    for (let i = 0; i < 5; i++) {
      uniqueCode = generateCode(5);
      const locatedSession = await get(child(dbRef, `sessions/${uniqueCode}`));
      if (locatedSession.exists()) {
        break;
      }
    }

    if (uniqueCode == null) {
      throw Error('Unable to generate code at this time.');
    }

    return uniqueCode;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function incrementProceedCount(sessionCode) {
  const sessionPath = `sessions/${sessionCode}`;
  const sessionRef = ref(getDatabase(), sessionPath);

  return runTransaction(sessionRef, (session) => {
    if (session) {
      if (session.proceedCount) {
        session.proceedCount++;
      } else {
        session.proceedCount = 1;
      }
    }
    return session;
  });
}

export async function checkSessionValid(uniqueCode, isHost, user) {
  const dbRef = ref(getDatabase());
  const locatedSession = await get(child(dbRef, `sessions/${uniqueCode}`));
  const sessionExists = locatedSession.exists();

  if (!sessionExists) {
    throw new Error('Invalid session code! No session was found.');
  }

  if (isHost) {
    const hostUid = locatedSession.child('ownerUid').val();
    if (user === null || hostUid != user.uid) {
      throw new Error('You are not the host!');
    }
  }

  return true;
}

export async function getLiveValues({
  uniqueCode,
  updateCountFunc,
  updateSessionFunc,
  updateCurrentRoundFunc,
  startSessionFunc
}) {
  const db = getDatabase();
  const sessionRef = ref(db, `sessions/${uniqueCode}`);
  const userCountRef = child(sessionRef, '/userCount');
  const sessionStateRef = child(sessionRef, '/sessionState');
  const currentRoundRef = child(sessionRef, '/currentRound');
  const maxUserCountRef = child(sessionRef, '/maxUserCount');

  // when the sessionState changes for the session
  if (updateSessionFunc != null) {
    onValue(sessionStateRef, (snapshot) => {
      const data = snapshot.val();
      if (data != null) {
        updateSessionFunc(data);
      }
    });
  }

  // when the currentRound changes for the session
  if (updateCurrentRoundFunc != null) {
    onValue(currentRoundRef, (snapshot) => {
      const data = snapshot.val();
      if (data != null) {
        updateCurrentRoundFunc(data);
      }
    });
  }

  // when the user count state changes for the session
  if (updateCountFunc != null) {
    onValue(userCountRef, async (snapshot) => {
      const data = snapshot.val();
      if (data != null) {
        updateCountFunc(data);
      }

      const maxUserCount = await get(maxUserCountRef);
      const sessionState = await get(sessionStateRef);

      if (sessionState.val() === 0 && data >= maxUserCount.val()) {
        console.log('test');
        startSessionFunc(uniqueCode);
      }
    });
  }
}

export function initialiseSession(maxUserCount, roundCount, imageCount, hostUser) {
  const db = getDatabase();

  maxUserCount = parseInt(maxUserCount, 10);
  roundCount = parseInt(roundCount, 10);
  imageCount = parseInt(imageCount, 10);

  return getUniqueCode(db)
    .then((uniqueCode) => {
      return set(ref(db, `sessions/${uniqueCode}`), {
        ownerUid: hostUser.uid,
        sessionState: 0,
        maxUserCount,
        roundCount,
        imageCount
      })
        .then(() => {
          return uniqueCode;
        });
    });
}

export function handleSessionStateChange(uniqueCode) {
  const db = getDatabase();
  const sessionRef = ref(db, `sessions/${uniqueCode}`);
  const userCountRef = child(sessionRef, '/userCount');
  const proceedCountRef = child(sessionRef, '/proceedCount');
  const sessionStateRef = child(sessionRef, '/sessionState');

  onValue(proceedCountRef, async (proceedCount) => {
    const userCount = await get(userCountRef);
    const sessionState = await get(sessionStateRef);

    if (proceedCount.val() == userCount.val()) {
      const currentSessionState = sessionState.val();
      await set(sessionStateRef, currentSessionState + 1);
      off(proceedCountRef);
      return await set(proceedCountRef, 0);
    }
  });
}

export async function joinSession(sessionCode, clientUid) {
  if (!sessionCode) {
    throw new Error('No session code provided!');
  }

  if (!clientUid) {
    throw new Error('No client UID provided!');
  }

  const sessionPath = `sessions/${sessionCode}`;
  const sessionRef = ref(getDatabase(), sessionPath);
  const locatedSession = await get(sessionRef);
  const sessionExists = locatedSession.exists();

  if (!sessionExists) {
    throw new Error('Invalid session code! No session was found.');
  }

  const maxUserCount = locatedSession.child('maxUserCount').val();
  if (locatedSession.child('userCount').val() >= maxUserCount) {
    throw new Error('Session is full!');
  }

  return set(child(sessionRef, `users/${clientUid}`), { readyState: false });
}

export async function readyClient(sessionCode, clientUid) {
  const sessionPath = `sessions/${sessionCode}`;
  const sessionRef = ref(getDatabase(), sessionPath);
  const clientUserRef = child(sessionRef, `users/${clientUid}`);

  const locatedUser = await get(clientUserRef);
  if (locatedUser.exists()) {
    if (!locatedUser.child('readyState').val()) {
      return set(clientUserRef, { readyState: true })
        .then(() => runTransaction(sessionRef, (session) => {
          if (session) {
            if (session.userCount) {
              session.userCount++;
            } else {
              session.userCount = 1;
            }
          }
          return session;
        }));
    }
  } else {
    const locatedSession = await get(sessionRef);
    const maxUserCount = locatedSession.child('maxUserCount').val();
    if (locatedSession.child('userCount').val() >= maxUserCount) {
      throw new Error('Session is full!');
    }
  }
}

export function startNextRound(sessionCode) {
  const sessionPath = `sessions/${sessionCode}`;
  const sessionRef = ref(getDatabase(), sessionPath);

  return runTransaction(sessionRef, (session) => {
    if (session) {
      if (session.currentRound) {
        session.currentRound++;
      } else {
        session.currentRound = 1;
      }

      session.sessionState = 1;
    }
    return session;
  });
}

export function getSessionData(sessionCode, path) {
  const sessionPath = `sessions/${sessionCode}`;
  const sessionRef = ref(getDatabase(), sessionPath);

  return get(child(sessionRef, path)).then((snapshot) => {
    if (snapshot.exists()) {
      return snapshot.val();
    }

    throw new Error('No data found!');
  });
}

export function uploadImages(sessionCode, clientUid, imageArray) {
  const sessionPath = `sessions/${sessionCode}`;
  const sessionRef = ref(getDatabase(), sessionPath);
  const clientUserRef = child(sessionRef, `users/${clientUid}`);

  const imageMap = {};
  imageArray.forEach((image, index) => imageMap[`image_${index}`] = image);

  return set(child(clientUserRef, '/roundImages'), imageMap)
    .then(() => incrementProceedCount(sessionCode));
}

export async function checkImagesExist(sessionCode, clientUid) {
  const sessionPath = `sessions/${sessionCode}`;
  const sessionRef = ref(getDatabase(), sessionPath);
  const clientUserRef = child(sessionRef, `users/${clientUid}`);

  const locatedUserImages = await get(child(clientUserRef, '/roundImages'));
  return locatedUserImages.exists();
}