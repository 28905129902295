import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Link } from '@mui/material';

function HostButtons(props) {
  if (props.accountState == 0) {
    return null;
  }

  if (props.accountState == 3) {
    return (
      <Button
        variant='contained'
        size='large'
        sx={{ width: '80%', mt: 2 }}
        onClick={() => props.openSessionModal()}
      >
        Host Session
      </Button>
    );
  }

  return (
    <Grid container>
      {props.accountState == 1 ?
        <>
          <Grid item xs>
            <Link href='#' variant='body2'>
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href='#' onClick={() => props.accountChange(2)} variant='body2'>
              Don&apos;t have an account? Sign Up
            </Link>
          </Grid>
        </> :
        <Grid item>
          <Link href="#" onClick={() => props.accountChange(1)} variant="body2">
            Already have an account? Sign in
          </Link>
        </Grid>
      }
    </Grid>
  );
}

HostButtons.propTypes = {
  accountState: PropTypes.number,
  openSessionModal: PropTypes.func,
  accountChange: PropTypes.func
};

export default HostButtons;