import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Typography
} from '@mui/material';
import { signOutUser } from '../../utils/apis/userApi';

function ActiveUser(props) {
  const getName = () => {
    const { displayName, email } = props.user;
    if (displayName) {
      return displayName;
    }

    if (email) {
      return email.split('@')[0];
    }

    return 'Anonymous User';
  };

  const getInitials = (name) => {
    const words = name.split(' ');
    return words.map(word => word[0].toUpperCase()).join('');
  };

  const handleSignOut = () => {
    signOutUser().then(() => window.location.reload());
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Paper elevation={2} sx={{ padding: 2 }}>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >
          <Grid item>
            <Avatar
              sx={{
                height: 50,
                width: 50,
                bgcolor: 'primary.main'
              }}
              src={props.user.photoURL}
            >
              {getInitials(getName())}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant='h6'>{getName()}</Typography>
          </Grid>
        </Grid>
        <Button
          fullWidth
          variant='contained'
          sx={{ mt: 1, bgcolor: 'secondary.main' }}
          onClick={handleSignOut}
        >
          Log Out
        </Button>
      </Paper>
    </Box>
  );
}

ActiveUser.propTypes = {
  user: PropTypes.object
};

export default ActiveUser;